<template>
  <div class="container py-5">
    <p class="fw-bold">IMCAS World Congress 2023</p>
    <p>時間：2023 01/26-28</p>
    <p>地點：PARIS</p>
    <p>
    The IMCAS World Congress 2023 has started! Will we see you there? 
    <br>
    Don't forget to visit our Sylton booth for a live demo with OBSERV 520x.
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_11.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
